import currentStatusReportService from '@/business/currentStatusReportService'
import { filterRulesMapped } from '@/tools/filterRules'
import { mapMutations, mapGetters } from 'vuex'
import { dateSortFunction } from '@/tools/functions'
import service from '@/middleware'

export default {
  name: 'HarvesterCurrentStatusReport',
  components: {
  },
  props: {
    tableRowClick: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: 'harvesterId',
    chartConfiguration: { },
    mapConfiguration: { },
    loadingTable: true,
    middleware: service.http
  }),
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    })
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.getData()
  },
  methods: {
    ...mapMutations('app', {
      setEntitySelectorItems: 'setEntitySelectorItems',
      setEntityConfiguration: 'setEntityConfiguration',
      resetEntitySelector: 'resetEntitySelector',
      setSelectedItem: 'setSelectedItem'
    }),
    onChartClick () {
    },
    refreshTable () {
      this.getData(true)
    },
    resize () {
      this.$eventBus.$emit('resizeCharts')
    },
    getData (refresh = false) {
      this.loadingTable = true
      currentStatusReportService.getReportNotDeferred([{ key: 'HARVESTER' }]).then(response => {
        if (!refresh) {
          this.setTableFilterRules(response.headers)
          this.tableHeaders.cleanAndUpdate(response.headers)
          /*
          Para ordenar las columnas fecha y hora
          TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
          */
          this.tableHeaders.find(header => header.value === 'date').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY') }
          this.tableHeaders.find(header => header.value === 'time').sort = (a, b) => { return dateSortFunction(a, b, 'HH:mm:ss') }
        }
        this.tableData.cleanAndUpdate(response.data)
        this.chartConfiguration = { graphs: response.charts }
        this.mapConfiguration = { maps: response.maps }
        this.loadingTable = false
      })
    },
    setTableFilterRules (headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    clickRow (data) {
      this.tableRowClick(data)
    }
  }
}
